import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable()
export class LocalStorageService {

    /**
     * Retrieve value from local storage
     * @returns {Observable<string>}
     */
    public getValue(key: string): Observable<string> {
        return of(<string>localStorage.getItem(key));
    }

    /**
     * Store Value in the local storage
     * @returns {LocalStorageService}
     */
    public setValue(key: string, value: string): LocalStorageService {
        localStorage.setItem(key, value);
        return this;
    }

    /**
     * Remove record from the local storage
     */
    public clear(key: string) {
        localStorage.removeItem(key);
        return this;
    }

    /**
     * Remove All records from the local storage
     */
    public clearAll() {
        localStorage.clear();
    }
}
