import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/index';
import {ApplicationHelperService} from '../../shared/helper';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../data-service';

@Injectable()
export class SubjectDataService implements DataService{
    constructor(

        private http: HttpClient,
        private helper: ApplicationHelperService
    ) { }

    /**
     * Get the full backend API URL to fetch the list of records
     * @returns {string}
     */
    getDataTableURL(): string {
        return this.helper.getBackendApiUrl('subjects/index');
    }

    getLookup(campus_id= 0, classe_id= 0): Observable<any> {
        let url = (campus_id > 0) ? ('subjects/' + campus_id) : 'subjects';
        if (classe_id) {
            url += '?classe_id=' + classe_id;
        }
        return this.http.get(
            this.helper.getBackendApiUrl(url)
        );
    }

    /**
     * send a POST request to the backend API to create a new record
     * @param {Object} formData
     * @returns {Observable<any>}
     */
    create(formData: Object): Observable<any> {
        return this.http.post(
            this.helper.getBackendApiUrl('subjects/store'), formData
        );
    }

    /**
     * Send a PUT request to the backend API to update an existing record
     * @param {number} id
     * @param {Object} formData
     * @returns {Observable<any>}
     */
    update(id: number, formData: Object): Observable<any> {
        return this.http.put(
            this.helper.getBackendApiUrl('subjects/' + id + '/update'), formData
        );
    }

    /**
     * Send a GET request to the backend API to fetch the details of a single record
     * @param {number} id
     * @returns {Observable<any>}
     */
    get(id: number): Observable<any> {
        return this.http.get(
            this.helper.getBackendApiUrl('subjects/' + id + '/show')
        );
    }

    /**
     * Send a DELETE request to the backend api to delete an existing record
     * @param {number} id
     * @returns {Observable<any>}
     */
    delete(id: number): Observable<any> {
        return this.http.delete(
            this.helper.getBackendApiUrl('subjects/' + id + '/delete')
        );
    }
}
