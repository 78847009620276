import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {DatatablesResponse} from "./datatables-response";
import {tap} from "rxjs/operators";
import {DataTableDirective} from "angular-datatables";

@Injectable()
export class DatatableService {

    constructor(private http: HttpClient) {}

    getDtAdvancedOptions(
        fullBackendUrl: string,
        columns: Array<any>,
        orderBy: Array<any>,
        extraParams: any = [],
        dtElement: DataTableDirective = null
    ): any
    {
        let btn: Array<any> = ['csv', 'excel', 'print', 'copy'];
        if(dtElement !== null && dtElement !== undefined)
            btn.push({
                text: 'Refresh',
                action: function (e, dt, node, config) {
                    dtElement.dtInstance
                        .then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
                }
            });

        return {
            pagingType: 'full_numbers',
            pageLength: 20,
            serverSide: true,
            deferRender: true,
            processing: true,
            order: orderBy,
            lengthMenu: [[20, 50, 100, 1000, -1], [20, 50, 100, 1000, "ALL"]],
            ajax: (dataTablesParameters: any, callback) => {
                dataTablesParameters.data = extraParams;
                this.http
                    .post<DatatablesResponse>(fullBackendUrl, dataTablesParameters)
                    .pipe(tap((resp) => callback(resp)))
                    .subscribe();
            },
            columns: columns,
            dom: 'Bfrtipl',
            buttons: btn
        };
    }

    getDtOptions(
        fullBackendUrl: string,
        columns: Array<any>,
        orderBy: Array<any>
    ): any
    {
        return {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            deferRender: true,
            processing: true,
            order: orderBy,
            lengthMenu: [[10, 25, 50, 100, 1000, -1], [10, 25, 50, 100, 1000, "ALL"]],
            ajax: (dataTablesParameters: any, callback) => {
                this.http
                    .post<DatatablesResponse>(fullBackendUrl, dataTablesParameters)
                    .pipe(tap((resp) => callback(resp)))
                    .subscribe();
            },
            columns: columns,
            dom: 'Bfrtipl',
            buttons: ['csv', 'excel', 'print', 'copy']
        };
    }

}
