<div class="wrapper">
    <section id="forgot-password">
        <div class="container-fluid">
            <div class="row full-height-vh">
                <div class="col-12 d-flex align-items-center justify-content-center gradient-aqua-marine">
                    <div class="card px-4 py-2 box-shadow-2">
                        <div class="card-header text-center">
                            <img src="../../assets/img/logos/logo-color-big.png" alt="company-logo" class="mb-3" width="80">
                            <h4 class="text-uppercase text-bold-400 grey darken-1">Change Your Password</h4>
                        </div>
                        <div class="card-body">
                            <div class="card-block">
                                <form class="pt-3" (ngSubmit)="submitForm()" #formData="ngForm" novalidate>

                                    <div *ngIf="errorMessage">
                                        <div class="alert alert-danger">
                                            <strong>{{ errorMessage }}</strong>
                                            <ul *ngIf="errorList">
                                                <li *ngFor="let error of errorList"> {{error}}</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <input type="password" name="password" [(ngModel)]="record.password"
                                               class="form-control form-control-lg"
                                               id="password" placeholder="Enter a new password" required>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" name="password_confirmation" [(ngModel)]="record.password_confirmation"
                                               class="form-control form-control-lg"
                                               id="password_confirmation" placeholder="Confirm your new password" required>
                                    </div>

                                    <div class="form-group pt-2">
                                        <div class="text-center mt-3">
                                            <button [disabled]="!formData.form.valid || loading" type="submit"
                                                    class="btn btn-danger px-4 py-2 text-uppercase white font-small-4 box-shadow-2 border-0">Change Password</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>




