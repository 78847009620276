import { Injectable } from '@angular/core';
import {DataService} from '../../data-service';
import {Observable} from 'rxjs/index';
import {ApplicationHelperService} from '../../shared/helper';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CampusDataService implements DataService{

    constructor(
        private http: HttpClient,
        private helper: ApplicationHelperService
    ) { }

    /**
     * Get the full backend API URL to fetch the list of records
     * @returns {string}
     */
    getDataTableURL(): string {
        return this.helper.getBackendApiUrl('campus/index');
    }

    getLookup(): Observable<any> {
        return this.http.get(
            this.helper.getBackendApiUrl('campus')
        );
    }

    /**
     * send a POST request to the backend API to create a new record
     * @param {Object} formData
     * @returns {Observable<any>}
     */
    create(formData: any): Observable<any> {
        return this.http.post(
            this.helper.getBackendApiUrl('campus/store'), formData
        );
    }

    /**
     * Send a PUT request to the backend API to update an existing record
     * @param {number} id
     * @param {Object} formData
     * @returns {Observable<any>}
     */
    update(id: number, formData: any): Observable<any> {
        return this.http.put(
            this.helper.getBackendApiUrl('campus/' + id + '/update'), formData
        );
    }

    /**
     * Send a GET request to the backend API to fetch the details of a single record
     * @param {number} id
     * @returns {Observable<any>}
     */
    get(id: number): Observable<any> {
        return this.http.get(
            this.helper.getBackendApiUrl('campus/' + id + '/show')
        );
    }

    /**
     * Send a DELETE request to the backend api to delete an existing record
     * @param {number} id
     * @returns {Observable<any>}
     */
    delete(id: number): Observable<any> {
        return this.http.delete(
            this.helper.getBackendApiUrl('campus/' + id + '/delete')
        );
    }
}
