import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LocalStorageService} from "../helper";
import {map} from "rxjs/operators";
import {AuthUser} from "./auth-user";

@Injectable()
export class TokenStorageService extends LocalStorageService{

    /**
     * Get access token
     * @returns {Observable<string>}
     */
    public getAccessToken(): Observable<string> {
        return this.getValue('accessToken');
    }

    /**
     * Get refresh token
     * @returns {Observable<string>}
     */
    public getRefreshToken(): Observable<string> {
        return this.getValue('refreshToken');
    }

    /**
     * Get Auth User
     * @returns {Observable<AuthUser>}
     */
    public getUser(): Observable<AuthUser> {
        return this.getValue('user')
            .pipe(map((value)=>{
                let val = JSON.parse(value);
                let ret = new AuthUser();
                ret.role = val.role;
                ret.name = val.name;
                ret.email = val.email;
                ret.username = val.username;
                ret.must_change_password = val.must_change_password;
                ret.status = val.status;
                return ret;
            }));
    }

    /**
     * Set access token
     * @returns {TokenStorageService}
     */
    public setAccessToken(token: string): TokenStorageService {
        this.setValue('accessToken', token);
        return this;
    }

    /**
     * Set auth user
     * @returns {TokenStorageService}
     */
    public setUser(user: any): TokenStorageService {
        this.setValue('user', JSON.stringify(user));
        return this;
    }

    /**
     * Set refresh token
     * @returns {TokenStorageService}
     */
    public setRefreshToken(token: string): TokenStorageService {
        this.setValue('refreshToken', token);
        return this;
    }

}
