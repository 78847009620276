import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class NotificationService {
    style = 'material';
    title = '';
    body = '';
    timeout = 3000;
    progressBar = false;
    closeClick = true;
    newTop = true;
    backdrop = -1;
    dockMax = 8;
    blockMax = 6;
    pauseHover = true;
    titleMaxLength = 15;
    bodyMaxLength = 80;

    constructor(private snotifyService: ToastrService) { }

    // On Success
    onSuccess($body): any {
        this.snotifyService.success($body, 'Success');
    }

    // On Info
    onInfo($body): any {
        this.snotifyService.info($body, 'Info');
    }

    // On Error
    onError($body): any {
        this.snotifyService.error($body, 'Error');
    }

    // On Warning
    onWarning($body): any {
        this.snotifyService.warning($body, 'Warning');
    }

    // onConfirmation() {
    //     this.position = SnotifyPosition.centerCenter
    //     const {timeout, closeOnClick, ...config} = this.getConfig(); // Omit props what i don't need
    //     this.snotifyService.confirm(this.body, this.title, {
    //         ...config,
    //         buttons: [
    //             {text: 'Yes', action: () => console.log('Clicked: Yes'), bold: false},
    //             {text: 'No', action: (toast) => {this.snotifyService.remove(toast.id)}, bold: true},
    //         ]
    //     });
    // }

    confirmPageExit(callBack): any {
        Swal.fire({
            title: 'Confirmation!',
            text: 'Are you sure you want to leave this page?',
            showCancelButton: true,
            confirmButtonColor: '#2f26d5',
            cancelButtonColor: '#ec2bc1',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false,
            icon: 'warning',
        }).then((result) => {
            if (result.value) {
                callBack();
            }
        });
    }

  confirmPageReset(callBack): any {
    Swal.fire({
      title: 'Confirmation!',
      text: 'Do you want to reset the data on this page?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2f26d5',
      cancelButtonColor: '#ec2bc1',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        callBack();
      }
    });
  }

    confirmDeletion(callBack): any {
        Swal.fire({
            title: 'Confirmation!',
            text: 'Are you sure you want to delete this record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f11352',
            cancelButtonColor: '#524be8',
            confirmButtonText: 'Yes, Delete it',
            cancelButtonText: 'No, Cancel'
        }).then((result) => {
            if (result.value) {
                callBack();
            }
        });
    }

    confirm(message: string, callBack): any {
        Swal.fire({
            title: 'Confirmation!',
            text: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f11352',
            cancelButtonColor: '#524be8',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                callBack();
            }
        });
    }
}
