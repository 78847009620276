import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable()
export class ApplicationHelperService {

  constructor() { }

    private apiUrl: string = environment.backendUrl;

    getFormValidationErrors(errArr: Array<any>){
        if(!errArr) return [];
        let errMsgArr = [];
        Object.keys(errArr).forEach(key => {
            errArr[key].forEach((item, index) => errMsgArr.push(item));
        });
        return errMsgArr;
    }

    getBackendApiUrl(serviceStr: string) {
        let prefix = this.apiUrl.endsWith('/') ? this.apiUrl : (this.apiUrl+'/');
        let suffix = serviceStr.startsWith('/') ? serviceStr : ('/'+serviceStr);
        return prefix + 'api' + suffix;
    }

    getBackendUrl(serviceStr: string) {
        let prefix = this.apiUrl.endsWith('/') ? this.apiUrl : (this.apiUrl+'/');
        let suffix = serviceStr.startsWith('/') ? serviceStr.substring(1) : (serviceStr);
        return prefix + suffix;
    }
}
