import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './not-found/not-found.component';
import {ProtectedGuard, PublicGuard} from 'ngx-auth';
import {MustChangePasswordComponent} from './must-change-password/must-change-password.component';

const routes: Routes = [
    {
        path: 'must-change-password',
        canActivate: [ProtectedGuard],
        component: MustChangePasswordComponent
    },
    {
        path: 'auth',
        canActivate: [PublicGuard],
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'main',
        canActivate: [ProtectedGuard],
        loadChildren: () => import('./main/main.module').then(m => m.MainModule)
    },
    { path: '', redirectTo: '/main/dashboard', pathMatch: 'full' },
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {useHash: true})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
