<div class="wrapper"><!--Error page starts-->
    <section id="error">
        <div class="container-fluid">
            <div class="row full-height-vh">
                <div class="col-12 d-flex align-items-center justify-content-center gradient-aqua-marine">
                    <div class="card px-3 py-2 box-shadow-2">
                        <div class="card-body">
                            <div class="card-block">
                                <div class="error-container">
                                    <div class="no-border">
                                        <div class="text-center text-bold-700 grey darken-2 mt-5" style="font-size: 11rem; margin-bottom: 4rem;">404</div>
                                    </div>
                                    <div class="error-body">
                                        <fieldset class="row py-2 pt-4">
                                            <div class="input-group col-12">
                                                <p style="text-align: center; width: 100%">The page you are looking for does not exists!</p>
                                            </div>
                                        </fieldset>
                                        <div class="py-2 justify-content-center">
                                            <div class="text-center">
                                                <a routerLink="/main/dashboard" class="btn btn-danger px-4 py-2 text-uppercase white font-small-4 box-shadow-2 border-0"><i class="ft-home"></i> Go to Homepage</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="error-footer bg-transparent">
                                        <div class="row">
                                            <p class="text-muted text-center col-12 py-1">© 2018 <a>Logiciensoft </a>Crafted with <i class="ft-heart font-small-3"></i> by <a href="http://logiciensoft.com" target="_blank">aamalan</a></p>
                                            <div class="col-12 text-center">
                                                <a class="btn btn-social-icon mr-1 mb-1 btn-facebook">
                                                    <span class="fa fa-facebook"></span>
                                                </a>
                                                <a class="btn btn-social-icon mr-1 mb-1 btn-twitter">
                                                    <span class="fa fa-twitter"></span>
                                                </a>
                                                <a class="btn btn-social-icon mr-1 mb-1 btn-linkedin">
                                                    <span class="fa fa-linkedin"></span>
                                                </a>
                                                <a class="btn btn-social-icon mr-1 mb-1 btn-github">
                                                    <span class="fa fa-github"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--Error page ends-->
</div>
