export class RouteHelperService {

    // main routes
    DASHBOARD = '/main/dashboard';
    AUDIT_TRAILS = '/main/audit-trails';
    MUST_CHANGE_PASSWORD = '/must-change-password';

    // profile routes
    ME_PROFILE = '/main/me';
    ME_UPDATE_PROFILE = '/main/me/update-profile';
    ME_CHANGE_PASSWORD = '/main/me/change-password';

    // roles routes
    ROLE_INDEX = '/main/roles';
    ROLE_CREATE = '/main/roles/create';
    ROLE_EDIT = '/main/roles/:$/edit';
    ROLE_VIEW = '/main/roles/:$/view';

    // users routes
    USER_INDEX = '/main/users';
    USER_CREATE = '/main/users/create';
    USER_EDIT = '/main/users/:$/edit';
    USER_RESET_PASSWORD = '/main/users/:$/reset-password';
    USER_VIEW = '/main/users/:$/view';

    // auth routes
    LOGIN = '/auth';
    REGISTER = '/auth/register';
    FORGOT_PASSWORD = '/auth/forgot-password';


    // contact routes
    CONTACT_INDEX = '/main/contacts';
    CONTACT_CREATE = '/main/contacts/create';
    CONTACT_EDIT = '/main/contacts/:$/edit';
    CONTACT_VIEW = '/main/contacts/:$/view';

    // subject routes
    SUBJECT_INDEX = '/main/subjects';
    SUBJECT_CREATE = '/main/subjects/create';
    SUBJECT_EDIT = '/main/subjects/:$/edit';
    SUBJECT_VIEW = '/main/subjects/:$/view';

    // campus routes
    CAMPUS_INDEX = '/main/campus';
    CAMPUS_CREATE = '/main/campus/create';
    CAMPUS_EDIT = '/main/campus/:$/edit';
    CAMPUS_VIEW = '/main/campus/:$/view';

    // academic years routes
    ACADEMIC_YEAR_INDEX = '/main/academic-years';
    ACADEMIC_YEAR_CREATE = '/main/academic-years/create';
    ACADEMIC_YEAR_EDIT = '/main/academic-years/:$/edit';

    ACADEMIC_YEAR_TERMS_INDEX = '/main/academic-years/:$/terms';
    ACADEMIC_YEAR_TERMS_CREATE = '/main/academic-years/:$/terms/create';
    ACADEMIC_YEAR_TERMS_EDIT = '/main/academic-years/:$/terms/:$/edit';

    // year groups routes
    YEAR_GROUP_INDEX = '/main/year-groups';
    YEAR_GROUP_CREATE = '/main/year-groups/create';
    YEAR_GROUP_EDIT = '/main/year-groups/:$/edit';

    // classes routes
    CLASSES_INDEX = '/main/classes';
    CLASSES_CREATE = '/main/classes/create';
    CLASSES_EDIT = '/main/classes/:$/edit';
    CLASSES_VIEW = '/main/classes/:$/view';

    // students records routes
    STUDENTS_RECORDS_INDEX = '/main/students/records';
    STUDENTS_RECORDS_CREATE = '/main/students/records/create';
    STUDENTS_RECORDS_EDIT = '/main/students/records/:$/edit';
    STUDENTS_RECORDS_VIEW = '/main/students/records/:$/view';
    STUDENTS_RECORDS_TRANSFER = '/main/students/records/:$/transfer';

  // students academic routes
    STUDENTS_ACADEMICS_INDEX = '/main/students/academics';
    STUDENTS_ACADEMICS_CREATE = '/main/students/academics/create';
    STUDENTS_ACADEMICS_EDIT = '/main/students/academics/:$/edit';
    STUDENTS_ACADEMICS_VIEW = '/main/students/academics/:$/view';

    STUDENTS_CLASS_LIST = '/main/students/class-list';

    // students terminal-report routes
    STUDENTS_TERMINAL_REPORT_INDEX = '/main/students/terminal-report';
    STUDENTS_TERMINAL_REPORT_EDIT = '/main/students/terminal-report/:$/edit';
    STUDENTS_TERMINAL_REPORT_VIEW = '/main/students/terminal-report/:$/view';

    // students promotions routes
    STUDENTS_PROMOTIONS_INDEX = '/main/students/promotions';
    STUDENTS_PROMOTIONS_EDIT = '/main/students/promotions/:$/edit';
    STUDENTS_PROMOTIONS_VIEW = '/main/students/promotions/:$/view';

    STUDENTS_BULK_PROMO_INDEX = '/main/students/bulk-promo';
    STUDENTS_PARENTS_NUMBERS_INDEX = '/main/students/parents-numbers';

    // finance fee items routes
    FINANCE_FEE_ITEM_INDEX = '/main/finance/fee-items';
    FINANCE_FEE_ITEM_CREATE = '/main/finance/fee-items/create';
    FINANCE_FEE_ITEM_EDIT = '/main/finance/fee-items/:$/edit';
    FINANCE_FEE_ITEM_VIEW = '/main/finance/fee-items/:$/view';

    // finance fees routes
    FINANCE_FEES_INDEX = '/main/finance/fees';
    FINANCE_FEES_CREATE = '/main/finance/fees/create';
    FINANCE_FEES_EDIT = '/main/finance/fees/:$/edit';
    FINANCE_FEES_VIEW = '/main/finance/fees/:$/view';

    // finance year group fees routes
    FINANCE_YEAR_GROUP_FEES_INDEX = '/main/finance/year-groups-fees';
    FINANCE_YEAR_GROUP_FEES_EDIT = '/main/finance/year-groups-fees/:$/edit';

    // finance classes fees routes
    FINANCE_CLASS_FEES_INDEX = '/main/finance/classes-fees';
    FINANCE_CLASS_FEES_EDIT = '/main/finance/classes-fees/:$/edit';

    // finance students fees routes
    FINANCE_STUDENT_FEES_INDEX = '/main/finance/students-fees';
    FINANCE_STUDENT_FEES_EDIT = '/main/finance/students-fees/:$/edit/:$';

    // finance billing routes
    FINANCE_BILLING_INDEX = '/main/finance/billing';
    FINANCE_BILLING_CREATE = '/main/finance/billing/create';
    FINANCE_BILLING_EDIT = '/main/finance/billing/:$/edit';
    FINANCE_BILLING_VIEW = '/main/finance/billing/:$/view';

    // finance billing routes
    FINANCE_PAYMENT_INDEX = '/main/finance/payments';
    FINANCE_PAYMENT_VIEW = '/main/finance/payments/:$/view';
    FINANCE_PAYMENT_CREATE = '/main/finance/payments/create/:$';
    FINANCE_PAYMENT_CREATE_WITH_ID = '/main/finance/payments/:$/create/:$';

    // finaance fee status report routes
    FINANCE_FEES_STATUS_REPORT_INDEX = '/main/finance/fee-status-report';
    FINANCE_FEES_STATUS_REPORT_VIEW = '/main/finance/fee-status-report/:$/:$/view/:$/:$/:$/:$';

    // student account statement routes
    FINANCE_ACCOUNT_STATEMENT_INDEX = '/main/finance/account-statement';
    FINANCE_ACCOUNT_STATEMENT_VIEW = '/main/finance/account-statement/:$/view/:$';

    // students bill
    FINANCE_STUDENT_BILL_INDEX = '/main/finance/students-bill';
    FINANCE_STUDENT_BILL_VIEW = '/main/finance/students-bill/:$/view/:$';
    FINANCE_STUDENT_BILL_EDIT = '/main/finance/students-bill/:$/edit/:$';

    // enrolment reports routes
    DAILY_RECORD_ENROLMENT_INDEX = '/main/daily-reports/enrolment';
    DAILY_RECORD_ENROLMENT_CREATE = '/main/daily-reports/enrolment/create';
    DAILY_RECORD_ENROLMENT_EDIT = '/main/daily-reports/enrolment/:$/edit';
    DAILY_RECORD_ENROLMENT_VIEW = '/main/daily-reports/enrolment/:$/view';

    // fees report routes
    DAILY_RECORD_FEES_INDEX = '/main/daily-reports/fees';
    DAILY_RECORD_FEES_CREATE = '/main/daily-reports/fees/create';
    DAILY_RECORD_FEES_EDIT = '/main/daily-reports/fees/:$/edit';
    DAILY_RECORD_FEES_VIEW = '/main/daily-reports/fees/:$/view';

    // summary report routes
    DAILY_RECORD_SUMMARY_INDEX = '/main/daily-reports/summary';

    buildRoute(url: string, params: Array<any>): string {
        const tempUrlArray = url.split(':$');
        if (tempUrlArray.length !== (params.length + 1)) {
            throw new Error(('Url parameters don\'t match with the values'));
        }
        let fullUrl = tempUrlArray[0];
        for (let i = 0; i < params.length; i++) {
            const currentParam = params[i];
            const currentPlaceholder = tempUrlArray[i + 1];
            fullUrl += ('' + currentParam + currentPlaceholder);
        }
        return fullUrl;
    }

    /**
     * Check the ability of a user to perform a specific action based on his role and permissions
     * @param {Array<string>} permissions list of the user permissions
     * @param {string} ability the ability to perform an action
     * @param {boolean} hasSuperAdminOnly set to true if the action on some/all records can ONLY be performed by the super admin or false(default) otherwise
     * @param extraData in case $hasSuperAdminOnly is set to true, pass the record containing the campus_id to only allow the user to perform the action only on his record(same campus) or set it to null(default) if only the admin should perform this action on all records
     * @returns {boolean} true if user is authorized or false otherwise
     */
    hasPermission(permissions: Array<string>, ability: string, hasSuperAdminOnly: boolean= false, extraData: any= null) {
        if (!hasSuperAdminOnly) {
            return permissions.indexOf(ability) > -1;
        }
        if (extraData && extraData.campus_id) {
            return ((permissions.indexOf(ability) > -1 && permissions.indexOf('campus_id_' + extraData.campus_id) > -1)
                || (permissions.indexOf(ability) > -1 && permissions.indexOf('super_admin') > -1));
        } else {
            return permissions.indexOf(ability) > -1 && permissions.indexOf('super_admin') > -1;
        }

    }
}
