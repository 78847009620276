import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RouteHelperService} from '../route-helper.service';
import {AuthenticationService} from '../shared/authentication';
import {ApplicationHelperService, NotificationService} from '../shared/helper';
import {HttpClient} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-must-change-password',
    templateUrl: './must-change-password.component.html',
    styleUrls: ['./must-change-password.component.css']
})
export class MustChangePasswordComponent implements OnInit {

    record: any = {};

    errorList = [];
    errorMessage = '';
    loading = false;

    constructor(
        private router: Router,
        private routeHelper: RouteHelperService,
        private authService: AuthenticationService,
        private helperService: ApplicationHelperService,
        private notifier: NotificationService,
        private spinnerService: NgxSpinnerService,
        private http: HttpClient
    ) { }

    ngOnInit() {
        this.authService.getAuthUser().subscribe((user) => {
            if (!user.must_change_password) {
                this.router.navigateByUrl(this.routeHelper.DASHBOARD);
            }
        });
    }

    submitForm() {
        this.errorMessage = '';
        this.errorList = [];
        this.spinnerService.show();
        this.loading = true;

        return this.http.put(
            this.helperService.getBackendApiUrl(
                'me/change-first-time-password'
            ), this.record
        ).subscribe((resp: any) => {
                    this.spinnerService.hide();
                    this.loading = false;
                    this.notifier.onSuccess(resp.message);
                    this.router.navigateByUrl(this.authService.getInterruptedUrl());
                },
                (err) => {
                    this.spinnerService.hide();
                    this.loading = false;
                    this.errorMessage = err.error.message;
                    if (err.status == 422) {
                        this.errorList = this.helperService.getFormValidationErrors(err.error.errors);
                    }
                });
    }
}
