import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AuthenticationModule, HelperModule} from './shared';
import {AppRoutingModule} from './app-routing.module';
import {AuthenticationService} from './shared/authentication';
import {RouteHelperService} from './route-helper.service';
import {AppComponent} from './app.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {MustChangePasswordComponent} from './must-change-password/must-change-password.component';
import {CampusDataService} from './main/campus/campus-data.service';
import {YearGroupDataService} from './main/year-group/year-group-data.service';
import {SubjectDataService} from './main/subject/subject-data.service';
import {AcademicYearDataService} from './main/academic-year/academic-year-data.service';
import {ClasseDataService} from './main/classe/classe-data.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';


@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        MustChangePasswordComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        HelperModule,
        AuthenticationModule,
        AppRoutingModule,
        NgxPermissionsModule.forRoot(),
        NgxSpinnerModule,
        ToastrModule.forRoot({
          positionClass: 'toast-bottom-right'
        }),
    ],
    providers: [
        AuthenticationService,
        {
            provide: APP_INITIALIZER,
          // tslint:disable-next-line:only-arrow-functions
            useFactory: (ds: AuthenticationService) => function() {
                ds.loadUserPermissions().subscribe();
            },
            deps: [AuthenticationService],
            multi: true
        },
        RouteHelperService,
        CampusDataService,
        YearGroupDataService,
        SubjectDataService,
        AcademicYearDataService,
        ClasseDataService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
