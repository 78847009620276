import { NgModule } from '@angular/core';
import {ApplicationHelperService} from "./application-helper.service";
import {NotificationService} from "./notification.service";
import {LocalStorageService} from "./local-storage.service";
import {DatatableService} from "./datatable.service";

@NgModule({
  providers: [
      ApplicationHelperService,
      NotificationService,
      LocalStorageService,
      DatatableService
  ]
})
export class HelperModule { }
