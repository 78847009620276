import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {AuthService} from 'ngx-auth';

import {TokenStorageService} from './token-storage.service';
import {environment} from "../../../environments/environment";
import {ApplicationHelperService} from "../helper";
import {AuthUser} from "./auth-user";
import {NgxPermissionsService} from "ngx-permissions";

interface AccessData {
    access_token: string;
    refresh_token: string;
}

@Injectable()
export class AuthenticationService implements AuthService {

    private interruptedUrl: string;

    constructor(
        private http: HttpClient,
        private helper: ApplicationHelperService,
        private tokenStorage: TokenStorageService,
        private permissionService: NgxPermissionsService
    ) {}

    getPicture() {
        return this.http.get(
            this.helper.getBackendApiUrl('me/picture')
        );
    }

    /**
     * Check, if user already authorized.
     * @description Should return Observable with true or false values
     * @returns {Observable<boolean>}
     * @memberOf AuthService
     */
    public isAuthorized(): Observable < boolean > {
        return this.tokenStorage
            .getAccessToken()
            .pipe(map(token => !!token));
    }

    /**
     * Get access token
     * @description Should return access token in Observable from e.g.
     * localStorage
     * @returns {Observable<string>}
     */
    public getAccessToken(): Observable < string > {
        return this.tokenStorage.getAccessToken();
    }

    /**
     * Function, that should perform refresh token verifyTokenRequest
     * @description Should be successfully completed so interceptor
     * can execute pending requests or retry original one
     * @returns {Observable<any>}
     */
    public refreshToken(): Observable <AccessData> {
        return this.tokenStorage
            .getRefreshToken()
            .pipe(
                switchMap((refreshToken: string) =>
                    this.http.post(this.helper.getBackendApiUrl('refresh'), {
                        grant_type: 'refresh_token',
                        refresh_token: refreshToken,
                        client_id: environment.clientId,
                        client_secret: environment.clientSecret
                    })
                ),
                tap((tokens: AccessData) => this.saveAccessData(tokens)),
                catchError((err) => {
                    this.permissionService.flushPermissions();
                    this.tokenStorage.clearAll();
                    location.reload();
                    return [];
                })
            );
    }

    /**
     * Function, checks response of failed request to determine,
     * whether token be refreshed or not.
     * @description Essentialy checks status
     * @param {Response} response
     * @returns {boolean}
     */
    public refreshShouldHappen(response: HttpErrorResponse): boolean {
        return response.status === 401
            && response.error
            && response.error.message
            && response.error.message.toLowerCase().indexOf('unauthenticated') !== -1
    }

    /**
     * Verify that outgoing request is refresh-token,
     * so interceptor won't intercept this request
     * @param {string} url
     * @returns {boolean}
     */
    public verifyTokenRequest(url: string): boolean {
        return url.endsWith('/refresh');
    }

    /**
     * EXTRA AUTH METHODS
     */

    public login(loginData): Observable<any> {
        return this.http.post(this.helper.getBackendApiUrl('login'), loginData)
            .pipe(mergeMap((resp: any) => {
                if(resp.message === 'successful') {
                    this.tokenStorage.setUser(resp.user);
                    return this.http.post(this.helper.getBackendUrl('oauth/token'), {
                        grant_type: environment.grantType,
                        client_id: environment.clientId,
                        client_secret: environment.clientSecret,
                        username: resp.user.email,
                        password: loginData.password
                    })
                    .pipe(
                        tap((tokens: AccessData) => this.saveAccessData(tokens)),
                        mergeMap((response) => this.loadUserPermissions())
                    );
                }
            }));
    }

    /**
     * Logout
     */
    public logout(): Observable<any> {
        return this.http.post(this.helper.getBackendApiUrl('logout'), {})
            .pipe(
                tap(() => this.permissionService.flushPermissions()),
                tap(() => this.tokenStorage.clearAll()),
                catchError((err) => {
                    this.permissionService.flushPermissions();
                    this.tokenStorage.clearAll();
                    return [];
                })
            );
    }

    public loadUserPermissions(): Observable<any> {
        let permdata: any = localStorage.getItem('permissions');
        if(permdata !== undefined && permdata !== null) {
            this.permissionService.loadPermissions(JSON.parse(permdata));
        }
        return this.isAuthorized().pipe(mergeMap((authResp) => {
            if(authResp) {
                return this.http.get(this.helper.getBackendApiUrl('me'))
                    .pipe(
                        tap((resp: any) => {
                            localStorage.setItem('permissions', JSON.stringify(resp.permissions))
                            this.permissionService.loadPermissions(resp.permissions);
                            this.tokenStorage.setUser(resp.user);
                        })
                    );
            }
            return of(authResp);
        }));
    }

    /**
     * Get the authenticated user
     * @returns {Observable<AuthUser>}
     */
    public getAuthUser(): Observable<AuthUser> {
        return this.tokenStorage.getUser();
    }

    /**
     * Save access data in the storage
     *
     * @private
     * @param {AccessData} data
     */
    private saveAccessData({ access_token, refresh_token }: AccessData) {
        this.tokenStorage
            .setAccessToken(access_token)
            .setRefreshToken(refresh_token);
    }

    public getInterruptedUrl(): string {
        return this.interruptedUrl;
    }

    public setInterruptedUrl(url: string): void {
        this.interruptedUrl = url;
    }

    public getHeaders(token: string): {[name: string]: string | string[]} {
        return {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+token,
            'Access-Control-Allow-Origin': '*'
        }
    };
}
