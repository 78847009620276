
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // backendUrl: 'http://schoolman.test',
  // clientSecret: '4xWf16fc12tApSGlVHDUawgn1eDoffHztr7ngmSP',
  // clientId: 1,

  // backendUrl: 'https://schoolman.ingeltechgh.com',
  // clientSecret: '0D16PD86ib5OVOkf01GakS2ENkgogalPzmsMIOEw',
  // clientId: 2,

  backendUrl: 'https://sms.idealcollegeonline.org',
  clientSecret: 'myIrsNmIeWikCqTDf55Y40dVmeLrUm6XuDQBscd8',
  clientId: 2,

  grantType: 'password',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
